@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap');

@layer components {

    .sidebar-main { 
        @apply fixed 
        md:top-0 left-0 
        h-screen w-24  m-0 flex bg-gray-900 text-white shadow-md flex-col
        bg-gradient-to-r from-gray-700 to-gray-600
        border-r border-white

        /* Mobile Navbar */
        sm:bottom-0 sm:left-0 sm:flex-row sm:w-screen sm:h-[12%] sm:border-t sm:border-r-0
        sm:bg-gradient-to-t sm:from-gray-700 sm:to-gray-600
    }

    .sidebar-icon {
        @apply relative flex items-center justify-center h-16 w-16 mt-4 mb-8 mx-auto
        bg-gray-800 p-2 
        hover:bg-white hover:text-gray-800
        text-gray-100 rounded-3xl hover:rounded-2xl transition-all
        cursor-pointer

        /* Mobile Buttons For Navbar */
        sm:w-14 sm:mb-6 sm:mx-1 
    }

    .sidebar-tooltip {
        @apply absolute w-auto p-2 m-2 min-w-max left-16
        sm:bottom-16 sm:w-[20px] sm:left-0
        rounded-md shadow-md text-white bg-white text-gray-800
        text-xs font-bold transition-all duration-100 scale-0 
        origin-left
    }
    .navpage {
        @apply transition-all duration-100 origin-left mx-auto
    }
}

.background-main {
    @apply h-screen bg-gradient-to-b from-gray-600 to-gray-900
}

body {
    font-family: Poppins, sans-serif;
}


